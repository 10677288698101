(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,
      lazyLoad: true,
      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,
      lazyLoad: true,
      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

// header behavior

window.addEventListener("scroll", function () {
  var topEyecatcher = document.querySelector(".top-eyecatcher");
  var bottomDiv = document.querySelector("header .bottom");
  var sab = document.querySelector(".mini-sab");
  var menu = document.querySelector(".menu");
  var navbarToggler = document.querySelector(".navbar-toggler");
  navbarToggler.ariaExpanded = false;
  // menu.classList.remove("show");
  if (topEyecatcher && bottomDiv) {
    if (window.scrollY > topEyecatcher.offsetHeight + 120) {
      bottomDiv.classList.add("sticky");
      menu.classList.add("sticky");
      if (sab) {
        sab.classList.add("sticky");
      }
    } else {
      bottomDiv.classList.remove("sticky");
      menu.classList.remove("sticky");
      if (sab) {
        sab.classList.remove("sticky");
      }
    }
  }
});

// usps popover

let icons = document.querySelectorAll("ul.usps-icon li");
icons.forEach((icon) => {
  icon.addEventListener("click", () => {
    icons.forEach((icon) => {
      icon.querySelector(".list-item-title").classList.remove("show");
    });
    icon.querySelector(".list-item-title").classList.toggle("show");
  });
});
